/*----------------------------------------------------------------------------*\
    $NAV
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.nav {
}
    .nav--primary {
        text-align: right;

        li {
        	@include rem(padding-left, 15px);
        	@include font-size(18px);

	        &.current {
	        	color: $brand;
	        	text-decoration: none;
	        }        	
        }
    }

    .nav--footer {
    	@extend .milli;

    	li {
    		display: block;
    	}
    }

    .nav--mobile-trigger {
        display: none;
        text-align: right;
    }



/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm) {

    .nav--mobile-trigger {
        display: block;
    }

    /**
     * Hide menu on homepage because image blocks are sufficient
     */
    .is-homepage .nav--primary,
    .is-homepage .nav--mobile-trigger{
        display: none;
    }

    .nav--primary {
        display: none;
        @include rem(margin-left, -$space);
        @include rem(margin-right, -$space);
        text-align: left;

        li {
            display: block;
            @include rem(padding, 0 $space);
        }
    }
}



/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
