/*----------------------------------------------------------------------------*\
    $BLOCKS
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.blocks {
}

    .blocks--bordered {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        @include rem(padding, $space 0);
    }

    .block{
    }
        .block__title {
            display: block;
            text-transform: uppercase;
            text-align: center;
            @include font-size(16px, 2);

            .block:hover & {
                color: $brand;
            }            
        }

        .block__image {
            position: relative;
            backface-visibility: hidden;
            overflow: hidden;

            img {
                vertical-align: top;
                filter: grayscale(100%);
                filter: gray;
                transition: .2s ease-out;

                .block:hover & {
                    filter: grayscale(0%);
                    filter: none;
                }
            }
        }

        .block__readmore {
            display: block;
            text-align: center;
        }

    .block--line {
        border: 1px solid #ccc;

        .block__title {
            @include font-size(16px, 4);
        }
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm) {
    .block--line {
        margin-bottom: $space;
    }
}



/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
