@import "~tiny-ui-toggle/src/styles/tiny-ui-toggle.scss";


.toggle-btn {
    color: $base-color;
    transition: all 0.3s;
    background-color: #F5F5F5;

    &:hover, &.is-active {
        color: $base-color;
    }
}

.toggle-panel-content {
    background: none;
    padding: 40px 0;
}
