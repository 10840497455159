/*----------------------------------------------------------------------------*\
    $BLOCKS
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.spotlight {
}
    .spotlight__holder {
        position: relative;
        border: 3px solid #9797A6;

        .flickity-page-dots {
            bottom: -35px;
        }

        .flickity-page-dots .dot {
            &.is-selected {
                background: $brand;
            }
        }
    }

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
