/*----------------------------------------------------------------------------*\
    $BUTTONS
\*----------------------------------------------------------------------------*/

.button{
}

    .button--primary {
        background-color: $brand;
        color: #fff;
        border: none;

        &:hover {
            background-color: darken($brand, 10%);
        }
    }
