/*----------------------------------------------------------------------------*\
    $FOOTER
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

$footer-height: 250px;

.footer {
    position: relative;
    background-color: #333;
    color: #fff;
    @include rem(padding, $space 0);
    z-index: 10;
}
    .footer__credits {
        @extend .milli;
        text-align: right;
    }

    .wrapper {
        margin-bottom: -$footer-height;
    }

    .wrapper:after {
      content: "";
      display: block;
    }

    .footer, .wrapper:after {
      height: $footer-height;
    }

    .footer__social-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .footer_instagram {
        @include font-size($milli);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 5px;
        margin-bottom: 20px;
    }
/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm-tablet-portrait) {
    .footer__credits {
        text-align: left;
    }
}



/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
