/*----------------------------------------------------------------------------*\
    $MODULE
    Removes the `margin-bottom` of a modules last-childs
    As per: http://css-tricks.com/spacing-the-bottom-of-modules/
\*----------------------------------------------------------------------------*/

.module,
%module {
    > *:last-child,
    > *:last-child > *:last-child,
    > *:last-child > *:last-child > *:last-child,
    > *:last-child > *:last-child > *:last-child > *:last-child {
        margin-bottom: 0;
    }
}