blockquote {
    quotes: "\201C""\201D""\2018""\2019";
    margin: 10px 0;
    font-style: italic;
    font-size: 18px;

    &:before {
        content: open-quote;
        color: #ccc;
        font-size: 1.5em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.2em;
    }

    &:after {
        content: close-quote;
        color: #ccc;
        font-size: 1.5em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }

    p {
        display: inline;
    }
}
