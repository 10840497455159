@if $use-custom-forms == true {
/*----------------------------------------------------------------------------*\
    $FORMS
    Basic form strucural styling. Extend in your theme stylesheet.
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MIXINS..................Mixins used only by this module
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    /**
     * Form field template
     *
     * Uses form-fields mixin to style all form fields in one swoop
     *
     * 1. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5,
     *    and Chrome.
     * 2. Improves appearance and consistency in all browsers.
     * 3. Corrects font size not being inherited in all browsers.
     */
    @include form-fields() {
        @include rem(padding, 5px 10px);
        display: inline-block;
        width: 100%;
        max-width: 430px;
        margin: 0; /* 1 */
        border: 1px solid $border-color;
        vertical-align: middle; /* 2 */
        font: inherit; /* 3 */
    }


    /**
     * Select element
     */
    .form__select {}
        select {
            width: 100%;
            max-width: 430px;
            @include font-size($base-font-size);
        }



    /* Form
    \*------------------------------------------------------------------------*/

        /**
         * Form template
         */
        .form {}
            .form__help {
                @include font-size($milli, .75);
            }

            .form__field-wrapper {
                @extend .cf;
                @include rem(margin-bottom, $space);
            }

            .form__label-wrapper {
                @include rem(margin-bottom, $space*.25);
            }

            .form__input-wrapper {
                @include form-fields() {
                    @include rem(margin-bottom, $space*.25);
                }
                select {
                    @include rem(margin-bottom, $space*.25);
                }

                textarea {
                    height: 168px;
                }
            }

            .form__button-wrapper {
                @include rem(margin-bottom, $space);
            }

            .form__checkbox-list,
            .form__radio-list {
                @include reset-list();
                margin-bottom: 0;

                span {
                    @include rem(padding-left, 5px);
                    @include font-size($milli, .75);
                }

                label {
                    padding-left: 5px;
                }
            }


        /**
         * Modifyer: Side by side
         * Places labels & fields next to eachother
         * Make responsive in you application level css
         */
        .form--side-by-side {
            /**
             * Field wrapper
             * extends `%grid` in `_mixins.scss`
             */
            .form__field-wrapper {
                @extend .grid;

                &.is-error {
                    @include rem(padding, $space-half 0);
                }
            }
                /**
                 * Label & input wrappers
                 * extends `%grid__cell` in `_mixins.scss`
                 */
                .form__label-wrapper,
                .form__input-wrapper {
                    @extend .grid__cell;
                }
                .form__label-wrapper {
                    width: 30%;
                    text-align: left;
                }
                .label-on-top .form__label-wrapper{
                    width: 100%;
                    margin-bottom: 10px;
                }
                .form__input-wrapper {
                    width: 70%;

                    &.no-label {
                        width: 100%;
                    }
                }
                .label-on-top .form__input-wrapper{
                    width: 100%;
                }
                    /**
                     * Help text
                     */
                    .form__help {
                        display: inline-block;
                    }
        }
        /* Opera hack, see grid system for documentation */
        .opera:-o-prefocus,
        .form--side-by-side .form__field-wrapper {
            word-spacing: -0.43em;
        }





/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /**
     * Parsley states
     */
    @include form-fields() {
        &.parsley-error,
        &.parsley-error:focus{
            border: 1px solid $color-error;
        }
        
        &.parsley-success,
        &.parsley-success:focus{
            border: 1px solid green;
        }
    }

    @include media-query(palm-tablet-portrait) {
        .form--side-by-side {

            .form__label-wrapper {
                display: none;
            }

            .form__input-wrapper {
                width: 100%;
            }
        }
    }

/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
} // use-base-forms
