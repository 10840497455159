/*----------------------------------------------------------------------------*\
    $VARS
    Any variables you find set in blocss _defaults.scss that you do not wish
    to keep, simply redefine here. This means that if blocss, for example,
    sets your `$bfs` at 16px and you wish it to be 14px, simply
    redeclare that variable in this file. Blocss ignores its own variables
    in favour of using your own, so you can completely modify how blocss
    works without ever having to alter the framework itself.
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $MODULES
    All of blocss objects and abstractions are initially turned off by
    default. This means that you start any project with as little as possible,
    and introducing objects and abstractions is as simple as switching the
    following variables to `true`.
\*----------------------------------------------------------------------------*/

    $use-custom-forms:                  true;


/*----------------------------------------------------------------------------*\
    $OVERRIDES
    Place any variables that should override blocss defaults here.
\*----------------------------------------------------------------------------*/

    $face-base:                         "Arial Narrow", "Arial", sans-serif;
    $legacy-support-for-ie8:            false;

    $breakpoints: (
        'palm'                  '(max-width: 480px)',
        'gt-palm'                  '(min-width: 480px)',
        'tablet-portrait'       '(min-width: 481px) and (max-width: 768px)',
        'palm-tablet-portrait'  '(max-width: 768px)',
        'tablet-landscape'      '(min-width: 769px) and (max-width: 1024px)',
        'lap'                   '(min-width: 769px) and (max-width: 1130px)',
        'desk'                  '(min-width: 1131px)',
        'lt-desk'               '(max-width: 1131px)'
    );

    $breakpoint-has-visuallyhidden:     (palm, palm-tablet-portrait, lt-desk, gt-palm);
    $breakpoint-has-units:              (palm, tablet-portrait, palm-tablet-portrait);
    $breakpoint-has-push:               (gt-palm);


    /*----------------------------------------------------------------------------*\
        $CUSTOM
        Place any of your own variables that sit on top of blocss here.
    \*----------------------------------------------------------------------------*/

    /* Toplevel
    \*------------------------------------------------------------------------*/

    $site-retain:                       1030px;

    /* Colours
    \*------------------------------------------------------------------------*/

    $brand:                             #71AACF;
    $base-color:                        #333;
    $border-color:                      #ccc;

    /* FORMS
    \*------------------------------------------------------------------------*/

    /**
     * List of input types used in the form-fields mixin.
     */
    $input-types:   text,
                    password,
                    date,
                    datetime,
                    email,
                    number,
                    search,
                    tel,
                    time,
                    month,
                    week,
                    url,
                    textarea,
                    text-input
    !default;

    $color-error:   red;
