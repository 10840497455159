/*----------------------------------------------------------------------------*\
    $TITLES
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.title {
}
    .title--footer {
        @include rem(margin-bottom, $space-half);
    }

    .title--horizontal {
        position: fixed;
        top: 50%;
        text-transform: uppercase;
        font-weight: normal;
        @include rem(font-size, 14px);
        margin: 0;

        &.title--horizontal__left {
            left: 10px;
            transform: rotate(-90deg);
        }

        &.title--horizontal__right {
            right: 10px;
            transform: rotate(90deg);
        }
    }

    .title--default {
        @include font-size(32px);
        @include rem(margin-bottom, $space);
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm) {
    .title--default {
        @include font-size(20px);
    }
}
/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
