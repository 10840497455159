table {
    width: 100%;
}

.table--striped {
    tr:nth-child(odd) {
        background-color: #F5F5F5;
    }
}

// wrap around table
.table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    .table {
        min-width: 500px;
    }
}

.table--exhibitions,
.table--literature {
    td {
        padding: 0 10px;
    }
}
