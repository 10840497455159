/*----------------------------------------------------------------------------*\
    $TOPLEVEL
    html, body, .wrapper
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    html,
    body { 
        height: 100%; 
    }

    body{
        position: relative;
        min-height: 100%;
        color: $base-color;
    }

    /**
     * Links
     */
    a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
            color: $brand;
        }
    }

    /**
     * Make wrapper 100% height
     */
    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
        z-index: 3;
    }     

    /**
     * Site retain
     * Contrains the site within the given boundaries
     */
    .site--retain {
        max-width: $site-retain + $space*2;
        margin-left: auto;
        margin-right: auto;
        @include rem(padding-left, $space);
        @include rem(padding-right, $space);
    }

        .site-retain--narrow {
            max-width: 648px;
        }

    /* extra grid class */
    .grid__cell--bottom {
        vertical-align: bottom;
    }

    .content {
        @include rem(margin-bottom, $space*2);
    }

    hr.rule {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    .js-click {
        cursor: pointer;
    }


/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
