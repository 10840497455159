/*----------------------------------------------------------------------------*\
    $HEADER
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.header {
}
    .header__top {
        background-color: #F5F5F5;
        border-bottom: 1px solid #CCCCCC;
        @include rem(padding, 15px 0);        
    }
    
    .header__nav {
        @include rem(padding, 15px 0);
    }

    .header__logo {
        display: inline-block;
        width: 100%;
        max-width: 326px;
        vertical-align: middle;

        img {
            display: block;
            max-height: 25px;
        }
    }



/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
