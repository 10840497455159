/*----------------------------------------------------------------------------*\
    $ARTICLE
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

/*
CONTENTS................You're reading it, LOL
MODULE..................Includes base module & submodules
STATE...................States the module can be in
THEME...................Theming of the module
*/





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.article {
    max-width: 700px;
    @extend %module;
}

    .article--full {
        max-width: none;
    }

    .article--bordered {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        @include rem(padding, $space 0);
    }

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
